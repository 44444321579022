export class SharedData {
  facebook_Link = 'https://www.facebook.com/AOULuigiVanvitelli';
  youtube_Link = 'https://www.youtube.com/channel/UCKH_h2kFKLffcgd0T9sm7Bw';
  twitter_Link = 'https://twitter.com/hashtag/vanvitelli';
  instagram_Link = 'https://www.instagram.com/Aou_luigi_vanvitelli/';
  prenotazioniCUPphone = '800 177 780';
  prenotazioniCUPemail = 'cup@policliniconapoli.it';
  pec = 'protocollo.policliniconapoli.it@pec.it';
  mail = 'protocollo@policliniconapoli.it';
  street = 'Via S.M. di Costantinopoli n°104';
  city = '80138 Napoli - Italia';
  codFisc_iva = 'Cod Fis – IVA 06908670638';

  URP_responsable = {
    TPN_DES: 'Responsabile',
    PER_SIGTIT: 'Dott.',
    PER_NOM: 'Federico',
    PER_COG: 'Margiasso',
    PER_TEL: '800 25 24 20',
    PHOTO_URL: '../../../assets/Ritiracartella.svg',
  };
  URL_location = { ORG_DES: 'Ufficio Relazioni con il Pubblico' };
  URP_number = '800252420';
  URP_fax = '0815666427';

  URP_officle_time = [
    { title: 'Lunedi', orarioApertura: '08:30', orarioChiusura: '16:00' },
    {
      title: 'Dal martedì al venerdì',
      orarioApertura: '08:30',
      orarioChiusura: '15.00',
    },
  ];

  URP_phone_numbers = [
    '0815666489',
    '0815666473',
    '0815666444',
    '0815666428',
    '0815666426',
  ];

  URP_reception_time = [
    { title: 'Lunedi', orarioApertura: '08:30', orarioChiusura: '15:30' },
    {
      title: ' Martedí, Mercoledí, Giovedí, Venerdí',
      orarioApertura: '08:30',
      orarioChiusura: '14:30',
    },
  ];

  Contacts = {
    title: 'CONTACTS_SUBVIEWS.TITLE',
    subtitle: 'CONTACTS_SUBVIEWS.SUBTITLE',
    seeMore: false,
    actions: [
      {
        iconName: 'locator.svg',
        text: 'Piazza Luigi Miraglia, 2 – 80138 Napoli (Italia)',
        addressLink:
          'https://www.google.com/maps/place/Piazza+Luigi+Miraglia,+Napoli+NA/@40.8498219,14.2515347,17z/data=!3m1!4b1!4m5!3m4!1s0x133b0843022c28f5:0x52165884cb941681!8m2!3d40.8498219!4d14.2537234',
        target: '_blank',
      },
      {
        iconName: 'Telefono.svg',
        text: '800 177 780',
        addressLink: 'tel:800 177 780',
      },
      {
        iconName: 'Telefono.svg',
        text: '081 184 08 313',
      },
    ],
    backgroundImage: '../../../assets/POLICLINICO_hd.jpg',
    page: 'booking',
    isContacts: true,
  };
  home = {
    title: 'HOME_SUBVIEWS.HOSPITAL_AGENCY',
    subtitle: 'HOME_SUBVIEWS.UNIVERSITY',
    seeMore: true,
    actions: [
      {
        iconName: 'prenotavisita.svg',
        text: 'HOME_SUBVIEWS.RESERVATION',
        addressLink: '/prenotazione-e-contatti',
        target: '_self',
      },
      {
        iconName: 'Ritiracartella.svg',
        text: 'HOME_SUBVIEWS.MEDICAL_RECORD',
        addressLink: '/ritiro-cartella-clinica',
        target: '_self',
      },
      {
        iconName: 'Dipartimenti2.svg',
        text: 'HOME_SUBVIEWS.DEPARTMENTS',
        addressLink: '/dipartimenti',
        target: '_self',
      },
    ],
    backgroundImage: '../../../assets/POLICLINICO_hd.jpg',
    page: 'home',
  };
  CampaniaPancreasInfoCard = {
    PER_COG: 'CONZO',
    PER_NOM: 'GIOVANNI',
    PER_SIGTIT: '',
    TPN_DES: '',
    PER_EMA: 'giovanni.conzo@unicampania.it',
    PER_TEL: '+39 330343566',
  };

  URP = [
    {
      type: 'simpleCardLink',
      text: 'Regolamento di Pubblica Tutela e Modulistica Reclami',
      path: '/azienda/urp/regolamento-e-pubblica-tutela',
    },

    {
      type: 'person',
      description: 'Responsabile',
      title: 'Dott.',
      name: 'Federico',
      surname: 'Margiasso',
      phone: '800 25 24 20',
      PHOTO_URL: '../../../assets/Ritiracartella.svg',
    },
    { type: 'simpleCard', text: 'Ufficio Relazioni con il Pubblico' },
    {
      type: 'number',
      description: 'Numero Verde',
      phone: '800252420',
      text: 'Servizio di segreteria telefonica attivo h24',
    },

    { type: 'number', description: 'Numero di telefono', phone: '0815666489' },
    { type: 'number', description: 'Numero di telefono', phone: '0815666426' },
    { type: 'number', description: 'Numero di telefono', phone: '0815666473' },
    { type: 'number', description: 'Numero di telefono', phone: '0815666444' },
    { type: 'number', description: 'Numero di telefono', phone: '0815666428' },
    { type: 'number', description: 'Numero di telefono', phone: '0815666474' },
    { type: 'mail', mailAddress: 'urp@policliniconapoli.it', text: 'Email' },
    {
      type: 'reception',
      title: 'Lunedí',
      text: 'Dalle ore 08:30 alle ore 16:00',
    },
    {
      type: 'reception',
      title: 'Dal martedì al venerdì',
      text: 'Dalle ore 08:30 alle ore 15.00',
    },
    {
      type: 'reception',
      title: 'Lunedí',
      text: 'Dalle ore 09:00 alle ore 12:30 e dalle ore 13:30 alle ore 15:30',
    },
    {
      type: 'reception',
      title: 'Mercoledì',
      text: 'Dalle ore 09:00 alle ore 12:30 e dalle ore 13:30 alle ore 14:30',
    },
    {
      type: 'reception',
      title: 'Martedí, Giovedí, Venerdí',
      text: 'Dalle ore 09:00 alle ore 12:30',
    },
  ];

  URP_NormativeText = [
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_1',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_2',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_3',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_4',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_5',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_6',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_7',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_8',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_9',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_10',
    },
    {
      NORMATIVE_TEXT: 'URP.NORMATIVE_TEXT_11',
    },
  ];

  // transparent administration cells links

  incnsg_CellLinks: any = [
    {
      title: 'Incarichi anno 2024 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it/?ente=DFP00000472&tipologiasoggetto=DIP&anno=2024',
    },
    {
      title: 'Incarichi anno 2023 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=DIP&anno=2023',
    },
    {
      title: 'Incarichi anno 2022 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=DIP&anno=2022',
    },
    {
      title: 'Incarichi anno 2021 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=DIP&anno=2021',
    },
    {
      title: 'Incarichi anno 2020 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=DIP&anno=2020',
    },
    {
      title: 'Incarichi anno 2019 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=DIP&anno=2019',
    },
  ];
  TICC_CellLinks: any = [
    {
      title: 'Incarichi anno 2024 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it/?ente=DFP00000472&tipologiasoggetto=CCE&anno=2024',
    },
    {
      title: 'Incarichi anno 2023 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=CCE&anno=2023',
    },
    {
      title: 'Incarichi anno 2022 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=CCE&anno=2022',
    },
    {
      title: 'Incarichi anno 2021 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=CCE&anno=2021',
    },
    {
      title: 'Incarichi anno 2020 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=CCE&anno=2020',
    },
    {
      title: 'Incarichi anno 2019 (PerlaPA)',
      link: 'https://consulentipubblici.dfp.gov.it?ente=DFP00000472&tipologiasoggetto=CCE&anno=2019',
    },
  ];

  // NavBar Links

  navItems = [
    {
      nav_id: 3,
      title: 'NAVBAR_TAB.HOME',
      path: '/home',
    },
    {
      nav_id: 4,
      title: 'NAVBAR_TAB.COMPANY',
      path: '/azienda',
      subMenu: [
        {
          nav_id: 13,
          title: 'NAVBAR_TAB.COMPANY',
          path: null,
          subLinks: [
            {
              id: 1,
              title: 'NAVBAR_TAB.UNIVERSITARY_COMPANY',
              path: '/azienda',
            },
          ],
        },
        {
          nav_id: 14,
          title: 'NAVBAR_TAB.MANAGEMENT',
          path: null,
          subLinks: [
            {
              nav_id: 220,
              title: 'NAVBAR_TAB.CIO',
              path: 'azienda/risk-management/cio-comitato-infezioni-ospedaliere',
            },
            {
              nav_id: 230,
              title: 'NAVBAR_TAB.RISK_MANAGEMENT',
              path: 'azienda/risk-management',
            },
          ],
        },
        {
          nav_id: 15,
          title: 'NAVBAR_TAB.ADMINISTRATION',
          path: null,
          subLinks: [
            {
              nav_id: 22,
              title: 'NAVBAR_TAB.TRANSPARENT_ADMINISTRATION',
              path: '/azienda/amministrazione-trasparente',
            },
            {
              nav_id: 28,
              title: 'NAVBAR_TAB.RLS',
              path: '/azienda/rls',
            },
            {
              nav_id: 27,
              title: 'NAVBAR_TAB.PROCEDURE_AZIENDALI',
              path: '/azienda/procedure-aziendali',
            },
            {
              nav_id: 23,
              title: 'NAVBAR_TAB.ETHICS_COMMITEE',
              path: '/azienda/comitato-etico',
            },
            {
              nav_id: 24,
              title: 'NAVBAR_TAB.PRIVACY',
              path: '/azienda/privacy',
            },
            {
              nav_id: 29,
              title: 'NAVBAR_TAB.MANUALE_QUALITA',
              path: '/azienda/manuale-qualita',
            },
            {
              nav_id: 25,
              title: 'NAVBAR_TAB.URP',
              path: '/azienda/urp',
            },
            {
              nav_id: 26,
              title: 'NAVBAR_TAB.LEGAL_NOTES',
              path: '/azienda/note-legali',
            },
          ],
        },
      ],
      links: [],
    },
    {
      nav_id: 5,
      title: 'NAVBAR_TAB.DEPARTMENTS',
      path: '/dipartimenti',
      subMenu: [],
      links: [],
    },
    {
      nav_id: 16584,
      title: 'NAVBAR_TAB.TENDERS_NOTICES',
      path: '/concorsi-e-avvisi',
      subMenu: [],
      links: [],
    },
    {
      nav_id: 6,
      title: 'NAVBAR_TAB.CONTESTS_1',
      path: '/bandi-e-gare',
      subMenu: [],
      links: [],
    },
    {
      nav_id: 7,
      title: 'NAVBAR_TAB.NEWS',
      path: '/news',
      subMenu: [],
      links: [],
    },
    {
      nav_id: 8,
      title: 'NAVBAR_TAB.BAR',
      path: '/albo-pretorio',
      subMenu: [],
      links: [
        {
          nav_id: 11,
          title: 'NAVBAR_TAB.RESOLUTIONS',
          nav_item_link: '/resolutions/delibere',
          path: null,
        },
        {
          nav_id: 12,
          title: 'NAVBAR_TAB.DEFINE',
          nav_item_link: '/resolutions/determine',
          path: null,
        },
      ],
    },
    {
      nav_id: 13,
      title: 'NAVBAR_TAB.ACTS_OF_NOTIFICATION',
      path: '/atti-di-notifica',
      subMenu: [],
      links: [],
    },
    {
      nav_id: 5,
      title: 'NAVBAR_TAB.TRANSPARENT_ADMINISTRATION',
      path: '/azienda/amministrazione-trasparente',
      subMenu: [],
      links: [],
    },
    {
      nav_id: 66,
      title: 'HEADER.CUP_AND_FOLDERS',
      path: '/Cup e ritiro cartelle',
      subMenu: [
        {
          nav_id: 13,
          title: '',
          path: null,
          subLinks: [
            {
              nav_id: 16,
              title: 'CONTACTS_SUBVIEWS.TITLE',
              nav_item_link: '/prenotazione-e-contatti',
              path: null,
            },
            {
              nav_id: 16,
              title: 'NAVBAR_TAB.WITHDRAWAL_MEDICAL_RECORDS',
              nav_item_link: '/ritiro-cartella-clinica',
              path: null,
            },
          ],
        },
      ],
      links: [],
    },
    {
      nav_id: 999,
      title: 'HEADER.RARE_DISEASE',
    },
    {
      nav_id: 7,
      title: 'HEADER.PANCREAS',
      path: '/campania_pancreas',
    },
  ];
}
