<!-- HEAD -->

<app-main-head
  [isSearchRequired]="true"
  [breadcrumbItems]="breadcrumbItems"
  [bodyList]="headNoticesList"
  [mainTitle]="'NOTICES.NOTICES'"
  [textPlaceholderSearch]="'NOTICES.NOTICES'"
  [rightListPanelText]="'NOTICES.NOTICES_IN_EVIDENCE'"
  [rightAllPanelText]="'NOTICES.ALL_NOTICES'"
  [redirectText]="'all-notices'"
  [routerLinkText]="'/concorsi-e-avvisi'"
  (externalSearchEvent)="searchExt($event)"
  [isRightPanelVisible]="false"
  [typology]="'notices'"
>
</app-main-head>
<div class="search-container">
  <search
    [textPlaceholder]="'Concorsi e avvisi'"
    (searchEvent)="searchExt($event)"
  ></search>
</div>

<!-- END HEAD -->

<div class="wrapper center">
  <!-- versione Mobile -->
  <div *ngIf="mobile">
    <app-main-accordion-table
      [dataSource]="noticesList"
      [typology]="typologyName"
      (detailHandler)="showDetail($event)"
      [hasDetail]="true"
    ></app-main-accordion-table>
  </div>
  <!-- versione Desktop -->
  <div class="center" *ngIf="!mobile">
    <table class="table">
      <tr class="table-header">
        <th class="colonna1">{{ "NOTICES_DETAIL.NAME" | translate }}</th>
        <th class="colonna2">
          {{ "NOTICES_DETAIL.START_VALIDITY" | translate }}
        </th>
        <th class="colonna3">{{ "NOTICES_DETAIL.DETAILS" | translate }}</th>
      </tr>
      <tbody>
        <tr *ngFor="let elem of noticesList">
          <td [ariaLabel]="elem.titolo">
            {{ elem.titolo }}
          </td>
          <td>
            {{ elem.iniziovalidita | date : "d MMMM YYYY" : "" : "it" }}
          </td>
          <td>
            <button
              class="btn detail-button"
              [routerLink]="['/concorsi-e-avvisi', elem.ID]"
            >
              {{ "NOTICES_DETAIL.DETAILS" | translate }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <p class="no_result_wrapper" *ngIf="noticesList.length == 0">
    {{ "ALERT_MESSAGE.NO_RESULTS_FOUND" | translate }}
  </p>
</div>
<!-- Paginator for notices all-->
<div>
  <app-paginator
    [list]="noticesList"
    [isIntoPaginationLimit]="limitPagination"
    [currentPage]="currentPage"
    [totalNumberOfPages]="totalNumberOfPages"
    (didClickedOnPage)="updateList($event)"
  >
  </app-paginator>
</div>
