import { Component, Input, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { departmentEmailsResponse } from 'src/app/shared/messageType';

@Component({
  selector: 'app-departments-detail-person',
  templateUrl: './departments_detail_person.component.html',
  styleUrls: [
    './departments_detail_person.component.scss',
    '../../../shared/sharedStyle.scss',
  ],
})
export class DepartmentsDetailPersonComponent implements OnInit {
  @Input() data;
  @Input() email: departmentEmailsResponse;
  @Input() showNumber = false;
  imagePath;
  image: any;

  constructor(private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.imagePath =
      this._sanitizer.sanitize(
        SecurityContext.RESOURCE_URL,
        this._sanitizer.bypassSecurityTrustResourceUrl(this.data.Foto_Direttore)
      ) || null;
  }
  onImageError() {
    this.imagePath = '../../../assets/Unknown_person.jpg';
  }
}
