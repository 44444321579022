<div class="sharedData_detail_body">
  <div
    class="sharedData_information_column"
    role="region"
    aria-label="Dettaglio urp"
  >
    <p class="title-text" id="title" role="article">
      {{ "URP.URP_EXTENDED" | translate }}
    </p>
    <p class="sharedData_text" role="article">{{ "URP.BODY" | translate }}</p>

    <p class="sub-title-text" id="contacts" role="article">
      {{ "URP.CONTACTS" | translate }}
    </p>
    <div>
      <div
        class="sharedData_information_cards_container"
        style="flex-direction: row-reverse; justify-content: flex-end"
      >
        <div *ngFor="let card of sharedData.URP | slice : 0 : 3">
          <app-URP-body-cell [data]="card"></app-URP-body-cell>
        </div>
      </div>
      <div class="sharedData_information_cards_container">
        <div *ngFor="let card of sharedData.URP | slice : 3 : 11">
          <app-URP-body-cell [data]="card"></app-URP-body-cell>
        </div>
      </div>

      <p class="sub-title-text" id="office_hour" role="article">
        {{ "URP.OFFICE_HOUR" | translate }}
      </p>

      <div class="sharedData_information_cards_container">
        <div *ngFor="let card of sharedData.URP | slice : 11 : 13">
          <app-URP-body-cell [data]="card"></app-URP-body-cell>
        </div>
      </div>

      <p class="sub-title-text" id="reception_hour" role="article">
        {{ "URP.RECEPTION_HOUR" | translate }}
      </p>

      <div class="sharedData_information_cards_container noWrap">
        <div *ngFor="let card of sharedData.URP | slice : 13 : 16">
          <app-URP-body-cell [data]="card"></app-URP-body-cell>
        </div>
      </div>

      <p class="title-text" id="reference_legislation" role="article">
        {{ "URP.REFERENCE_LEGISLATION" | translate }}
      </p>

      <p
        *ngFor="let text of sharedData.URP_NormativeText"
        class="normative_text"
        role="article"
      >
        {{ text.NORMATIVE_TEXT | translate }}
      </p>
    </div>
  </div>
</div>
