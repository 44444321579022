/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  // ricerca dipartimenti
  static readonly departmentSearchPath = '/api/v1/departments_reb/_search';
  departmentSearch(
    body?: { ID?: string; published: string },
    params?: {
      page?: number;
      limit?: number;
    }
  ) {
    let fullUrl = this.rootUrl + DepartmentsService.departmentSearchPath;

    return this.http.post<any>(fullUrl, { ...body });
  }

  // setta stato pubblicazione dei dipartimenti
  static readonly departmentSetStatePath =
    '/api/v1/departments_reb/_put_published';
  departmentSetState(params?: { ID: string; published: string }) {
    let fullUrl = this.rootUrl + DepartmentsService.departmentSetStatePath;
    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }

  // elimina dipartimenti
  static readonly departmentDeletePath = '/api/v1/departments_reb/_delete';
  departmentDelete(params?: { ID: string }) {
    let fullUrl = this.rootUrl + DepartmentsService.departmentDeletePath;
    const headers = this.getHeader();
    const options = {
      headers: headers,
      params: params,
    };
    return this.http.delete<any>(fullUrl, options);
  }

  //inserisce nuovo dipartimento
  static readonly departmentNewPath = '/api/v1/departments_reb/_insert';
  departmentNew(params?: {
    Titolo: string;
    Nome_Direttore: string;
    Luogo_Dipartimento: string;
    CV_Direttore: string;
    Foto_Direttore: string;
    published: string;
  }) {
    let fullUrl = this.rootUrl + DepartmentsService.departmentNewPath;
    const headers = this.getHeader();
    return this.http.post<any>(fullUrl, { ...params }, { headers });
  }

  //modifica il dipartimento
  static readonly departmentEditPath = '/api/v1/departments_reb/_update';
  departmentEdit(params?: {
    ID: string;
    Titolo: string;
    Nome_Direttore: string;
    Email_Direttore: string;
    Luogo_Dipartimento: string;
    CV_Direttore: string;
    Foto_Direttore: string;
    published: string;
  }) {
    let fullUrl = this.rootUrl + DepartmentsService.departmentEditPath;
    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }

  // ricerca UOC UODS Programmi
  static readonly strcturesSearchPath =
    '/api/v1/departments_reb/strctures/_search';
  strcturesSearch(
    body?: {
      ID_Dai?: string;
      Tipologia_Struttura?: string;
      published?: string;
      Titolo?: string;
    },
    params?: {
      page?: number;
      limit?: number;
    }
  ) {
    let fullUrl = this.rootUrl + DepartmentsService.strcturesSearchPath;
    return this.http.post<any>(fullUrl, { ...body, ...params });
  }

  // cerca ambulatori associati
  static readonly strcturesSearchByIDPath =
    '/api/v1/departments_reb/strctures/_associate_search';
  strcturesSearchByIDPath(params?: {
    ID_Dai?: string;
    Tipologia_Struttura?: string;
  }) {
    let fullUrl = this.rootUrl + DepartmentsService.strcturesSearchByIDPath;
    return this.http.post<any>(fullUrl, { ...params });
  }

  //inserisci UOC UOSD PROGRAMMI
  static readonly insertsUOCUOSDProgramsPath =
    '/api/v1/departments_reb/strctures/_insert';
  insertsUOCUOSDPrograms(params?: {
    Nome_Struttura?: string;
    ID_Direttore?: string;
    Tipologia_Struttura?: string;
    ID_Dai?: string;
  }) {
    let fullUrl = this.rootUrl + DepartmentsService.insertsUOCUOSDProgramsPath;
    const headers = this.getHeader();
    return this.http.post<any>(fullUrl, { ...params }, { headers });
  }

  //cancella strutture
  static readonly deleteUOCUOSDProgramsPath =
    '/api/v1/departments_reb/strctures/_delete';
  deleteUOCUOSDPrograms(params?: { ID: string }) {
    let fullUrl = this.rootUrl + DepartmentsService.deleteUOCUOSDProgramsPath;
    const headers = this.getHeader();
    const options = {
      headers: headers,
      params: params,
    };
    return this.http.delete<any>(fullUrl, options);
  }

  //modifica strutture
  static readonly updateUOCUOSDProgramsPath =
    '/api/v1/departments_reb/strctures/_update';
  updateUOCUOSDPrograms(params?: {
    ID?: string;
    Nome_Struttura?: string;
    ID_Direttore?: string;
  }) {
    let fullUrl = this.rootUrl + DepartmentsService.updateUOCUOSDProgramsPath;
    const headers = this.getHeader();
    return this.http.put<any>(fullUrl, { ...params }, { headers });
  }
  // leggi di piu delle strutture
  static readonly readMoreSearchPath =
    '/api/v1/departments_reb/strctures/more_info/_search';
  readMoreSearch(params?: { ID_Struttura: string }) {
    let fullUrl = this.rootUrl + DepartmentsService.readMoreSearchPath;
    return this.http.post<any>(fullUrl, { ...params });
  }

  getHeader() {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    });
    return headers;
  }
}
